<template>
    <v-container fluid class="pa-0">
      <template>
        <v-dialog
            v-model="dialogForm"
            persistent
            max-width="420px"
          >
            <template v-if="dialogForm">
              <QrEdit
                :order="editedItem"
                :bank="bank"
                @cancel="closeDialog()"
              ></QrEdit>
            </template>
          </v-dialog>
        <v-card>
  
          <v-data-table
            :headers="headersTable"
            :items="orderList"
            hide-actions
            no-data-text="Клиентов не найдено"
            no-results-text="Клиентов не найдено"
            :loading="tableLoading"
          >
          <template slot="items" slot-scope="props">
            <!-- <td class="text-xs-right">
              {{ props.item.id }}
            </td> -->
            <td>{{ props.item.client.name }}</td>
            <td>
                <v-text-field
                :rules="[v => !!v || 'Заполните поле']"
                placeholder="amount"
                type="number"
                v-model="props.item.cost"
                ></v-text-field>
            </td>
            <td>
                <v-btn color="info" @click="editItem(props.item)">
                    Pay
                </v-btn>
            </td>
          </template>
          </v-data-table>
        </v-card>
      </template>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import QrEdit from './edit.vue';
  
  export default {
    name: 'Qr',
    components: {
        QrEdit,
    },
    data() {
      return {
        loadingData: [
          {
            title: 'Получение клиентов',
            error: false,
            loading: false,
            color: 'indigo',
            id: 'clients',
          },
        ],
        headersTable: [
        //   {
        //     text: 'ID',
        //     align: 'right',
        //     value: 'id',
        //     sortable: true,
        //   },
          {
            text: 'Клиент',
            align: 'left',
            value: 'name',
            sortable: true,
          },
          {
            text: 'Amount',
            align: 'left',
            value: 'name',
            sortable: true,
          },
          {
            text: '',
            align: 'right',
            sortable: false,
            value: 'action',
          },
        ],
        dialogForm: false,
        editedItem: {},
        tableLoading: false,
        orderList: [],
        // order_ids: "12405,12404", //localhost
        order_ids: "16275,16274", // server
        bank:{},
      };
    },
    computed: {
      
    },
    methods: {
      bankURL() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-qr-bank`;
        const propsItem = {
            amount: parseFloat(this.editedItem.cost),
            order_id: parseInt(this.editedItem.id),
            page_type: 'charging',
        };
        
        axios
            .post(url, propsItem)
            .then((response) => {
            const item = response.data;
            console.log(item)
            if (item.payload) {
                // this.bankOrderId = item.orderId
                this.qrCodeUrl = item.payload;
                this.editedItem.bank_payments = item.bank
                this.bank = item.bank
                console.log(this.editedItem.bank_payments)
                this.dialogForm = true;
            } else {
                this.qrCodeUrl = null;
                this.dialogForm = fasle;
                this.showQr = false;
                console.error('qrCodeUrl not found in response');
            }
            })
        },
      closeDialog() {
        this.dialogForm = false;
        this.showQr = false;
      },
      getOrderList() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders`

        const propsItem = {
          order_ids: this.order_ids
        };
        axios
          .get(url, {
            params: propsItem,
          })
          .then((response) => {
            const items = response.data;
            this.orderList = items;
          })
          .catch((error) => {
            loadData.title = 'Ошибка получения клиентов!';
            loadData.error = true;
            console.log(error);
          });
      },
      editItem(item) {
        this.editedItem = item;
        this.bankURL()
      },
    },
    mounted() {
      this.getOrderList();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .v-table {
    tr:nth-child(even) {
      td {
        background: #f9f9f9;
      }
  
      &:hover {
        td {
          background: #eee;
        }
      }
    }
  }
  </style>
  