<template>
    <v-card>
      <v-card-title
        class="px-2 py-1"
      >
        <span class="headline" style="margin: 0 auto;">К оплате {{ order.cost }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-2 pt-3 text-align-center"
      >
         <v-alert
         :value="paidSuccess"
         type="success"
         class="my-0"
         >Оплачено!</v-alert>
         <QrcodeGenerator :qrValue="generateQrValue()"/>
     </v-card-text>
     <v-card-actions
         class="px-4 py-2"
       >
         <v-btn
           @click="cancel"
         >Отмена</v-btn>
     </v-card-actions>
    </v-card>
 </template>
 
 <script>
 import axios from 'axios';
 import QrcodeGenerator from '../../components/QrcodeGenerator.vue';
 
 export default {
   components: {
     QrcodeGenerator
   },
   props: {
     bank: {
       type: Object,
       required: true,
     },
     order: {
       type: Object,
       required: true,
     },
   },
   data() {
     return {
       comment: '',
       paidSuccess: false,
       statusInterval: null,
     };
   },
   methods: {
     cancel() {
        if (this.statusInterval) {
            clearInterval(this.statusInterval);
            this.statusInterval = null;
        }
       this.$emit('cancel');
     },
     generateQrValue() {
       console.log("this.bank.bank_url", this.bank.bank_url);
       return this.bank.bank_url;
     },
     startPollingStatus(orderId) {
       // Clear any existing interval to avoid duplicates
       if (this.statusInterval) {
         clearInterval(this.statusInterval);
       }
 
       // Set up polling every 10 seconds
       this.statusInterval = setInterval(() => {
         this.getStatus();
       }, 2000); // 2 seconds
     },
     getStatus() {
       const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-get-bank-status`;
       const propsItem = {
         bank_order_id: this.bank.bank_order_id,
       };
 
       axios
         .post(url, propsItem)
         .then((response) => {
           const status = response.data.orderStatus;
           const paymentStatus = response.data.paymentAmountInfo.paymentState;
           // Stop polling if the order is completed or canceled
           if (status == 2 || paymentStatus == 'DEPOSITED') {
             clearInterval(this.statusInterval);
             this.statusInterval = null;
             this.paidSuccess = true;
             setTimeout(() => {
               this.cancel(); // Use `this.cancel()` to ensure the correct context
             }, 1000);
           }
         })
         .catch((error) => {
           console.error(`Error fetching status for order:`, error);
         });
     },
   },
   mounted() {
     // Start polling when the component is mounted
     this.startPollingStatus(this.bank.bank_order_id);
   },
 };
 </script>
 
 <style>
 .text-align-center {
   text-align: center;
 }
 </style>